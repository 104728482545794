import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import fwLogo from "../assets/fastway-logo.svg"
import eqLogo from "../assets/equalizer-logo.svg"
import { Container, Row, Col } from "reactstrap"
import styles from "../pages/css/index.module.css"

export default ({data}) => {
  const content = data.craft.content[0];

	return (
    <div>
      <Header />
      <div className={styles.hero}>
        <Container>
          <Row>
            <Col xs="12" md="8">
              <div className={styles.heroContent}>
                <h1>{content.aboveFoldHeadline}</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: content.aboveFoldContent }}
                ></div>
              </div>
              <div className={styles.heroLogos}>
                <ul>
                  <li>
                    <a
                      href="https://www.equalizerhitch.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={eqLogo} alt="Equal-i-zer Logo" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.fastwaytrailer.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={fwLogo} alt="Fastay Trailer Logo" />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs="12" md="4">
              <aside className={styles.sideBar}>
                <div className={styles.sideBarHeader}>
                  What do I need to do?
                </div>
                <div className={styles.sideBarContent}>
                  <ol>
                    {content.whatDoINeedToDoSteps.map((step, index) =>
											<li key={index} className={styles.sideBarContentListItem} dangerouslySetInnerHTML={{ __html: step.stepDescription }}></li>
										)}
                  </ol>
                </div>
              </aside>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={{ offset: 2, order: 2, size: 8 }} sm="12">
            <div className={styles.homeLetter}>
              <h2>{content.mainContentHeadline}</h2>
              <div
                dangerouslySetInnerHTML={{ __html: content.mainContent }}
              ></div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export const query = graphql`
	query MainPage {
		craft {
			content: entries(siteId: "4", slug: "bpp-home", status: "disabled") {
				... on craft_bppHome_bppHome_Entry {
					id
					aboveFoldHeadline
					aboveFoldContent 
					whatDoINeedToDoSteps {
						... on craft_whatDoINeedToDoSteps_step_BlockType {
							stepDescription
						}
					}
					mainContentHeadline
					mainContent
				}
			}
		}
	}
`;